/* Add this CSS to your styles or create a separate stylesheet */

.card {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .card-body {
    height: 300px;
    overflow-y: auto;
    padding: 10px;
  }
  
  .message {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 5px;
  }
  
  .sent {
    background-color: #3498db;
    color: #fff;
  }
  
  .received {
    background-color: #ecf0f1;
    color: #333;
  }
  
  .card-footer {
    padding: 10px;
  }
  
  .input-group {
    width: 100%;
  }
  
  .form-control {
    border-radius: 0;
  }
  
  .btn-primary {
    border-radius: 0;
  }
  